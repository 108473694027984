import { Component, Fragment } from 'react'
import axios from 'axios'
import store from 'store'
import CryptoJS from 'crypto-js'

import SwiperCore, { Autoplay, EffectFade, EffectCube, EffectFlip, EffectCoverflow } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import * as dayjs from 'dayjs'
import 'dayjs/locale/zh-cn' // 导入本地化语言
import 'dayjs/locale/zh-hk'
import 'dayjs/locale/en'

import { eq } from '../../utils/func'

import './index.scss'

import ImageLoading from '../../asserts/loading.gif'

SwiperCore.use([Autoplay, EffectFade, EffectCube, EffectFlip, EffectCoverflow])

const LANGMMAP = {
  'zh_CN': 'zh-cn',
  'zh_HK': 'zh-hk',
  'en': 'en'
}

const I18NMAP = {
  'zh_CN': {
    refresh: '刷新',
    clear: '清缓存',
    play: '播放',
    pause: '暂停',
    mute: '静音',
    unmute: '声音',
  },
  'zh_HK': {
    refresh: '刷新',
    clear: '清快取',
    play: '播放',
    pause: '暂停',
    mute: '靜音',
    unmute: '聲音',
  },
  'en': {
    refresh: 'Refresh',
    clear: 'Clear',
    play: 'Play',
    pause: 'Pause',
    mute: 'Mute',
    unmute: 'Unmute'
  }
}

class Screen extends Component {

  state = {
    host: 'https://sapi.jdyyes.com',
    storageName: 'screen',
    windowWidth: 1,
    windowHeight: 1,
    screen: {
      lang: 'zh_CN',
      rotate: 0,
      refresh: 5,
      page: {
        width: 0,
        height: 0,
        left: 0,
        top: 0
      },
      widgetList: []
    },
    goldPrice: {},
    goldRate: 1,
    fontManage: {},
    fontsLoaded: [],
    imageHost: '',
    timerManage: {},
    errorMessages: {},
    muted: true,
    play: true,
    linkRotate: undefined,
    linkLang: undefined
  }

  async componentDidMount () {
    window.addEventListener('offline', () => {
      this.updateErrorMessages({
        action: 'add',
        error: 'NETWORK_ERROR',
        message: '请检查网络'
      })
    })
    window.addEventListener('online', () => {
      this.updateErrorMessages({
        action: 'remove',
        error: 'NETWORK_ERROR'
      })
    })

    document.addEventListener('click', (e) => {
      console.log(e)
    }, false)

    this.setState({
      windowWidth: window.innerWidth || document.body.clientWidth || 1,
      windowHeight: window.innerHeight || document.body.clientHeight || 1
    })

    const { id, rotate, lang } = this.getUrlQuery()
    if (!id) {
      this.updateErrorMessages({
        action: 'add',
        error: 'LINK_ERROR',
        message: 'LINK ERROR'
      })
      return
    }
    this.setState({ linkRotate: rotate, linkLang: lang })
    this.updateErrorMessages({
      action: 'remove',
      error: 'LINK_ERROR'
    })

    const id_decrypt = this.decryptByDES(id).split('-')
    const storage = store.get(this.state.storageName) || {}
    if (storage?.company_id + '' === id_decrypt[1] && storage?.store_id + '' === id_decrypt[2]) {
      this.setState({ ...storage }, async () => {
        await this.loadFonts()
      })
    }

    await this.getScreenInfo()

    this.videoPlay()

    this.setTimer()
  }

  componentWillUnmount () {
    this.setState = () => false
    this.timer = null
    clearInterval(this.timer)
  }

  decryptByDES (ciphertext, key = '2bao') {
    const keyHex = CryptoJS.enc.Utf8.parse(key)
    const decrypted = CryptoJS.DES.decrypt({
      ciphertext: CryptoJS.enc.Hex.parse(ciphertext)
    }, keyHex, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    })
    return decrypted.toString(CryptoJS.enc.Utf8)
  }

  getUrlQuery () {
    let query = ''
    if (window.location.search) {
      query = window.location.search.substr(1)
    } else {
      query = window.location.href.replace(/^\S+\?/g, '')
    }
    query = query.split('&')
    let params = {}
    for (let i = 0; i < query.length; i++) {
      let q = query[i].split('=')
      if (q.length === 2) {
        params[decodeURIComponent(q[0])] = decodeURIComponent(q[1])
      }
    }
    return params
  }

  formatNumber (num, len = 0) {
    if (!num) return ''
    let _num = parseFloat(num)
    if (isNaN(_num)) return num
    return parseFloat(_num).toFixed(len)
  }

  updateErrorMessages ({ action, error, message }) {
    let err = { ...this.state.errorMessages }
    if (action === 'add') {
      err[error] = { error, message } 
    } else if (action === 'remove') {
      delete err[error]
    }
    this.setState({ errorMessages: err })
  }

  isEqual () {
    const storage = store.get(this.state.storageName)
    if (!storage) return true
    const { screen } = this.state
    return eq(storage.screen, screen)
  }

  getScreenInfo (isInTimer = false) {
    this.loading = true
    return new Promise(async resolve => {
      try {
        const { id } = this.getUrlQuery()
        const res = await axios.post(`${this.state.host}/api/official_accounts/screens/detail`, {
          id: (this.decryptByDES(id).split('-'))[0],
          store_id: '-1'
        })
        if (res.status === 200 && res?.data?.code === 0) {
          this.updateErrorMessages({
            action: 'remove',
            error: 'REQUEST_ERROR'
          })
          const data = res.data.data
          this.setState({
            screen: {
              ...this.state.screen,
              ...data.config
            },
            goldPrice: data.extra?.gold_prices,
            fontManage: data.extra?.fonts,
            imageHost: res.data?.extra?.cdn_host,
            goldRate: res.data?.extra?.rate,
          }, async () => {
            if (isInTimer) this.shouldRefresh = !this.isEqual()
            await this.loadFonts()
            const { storageName, screen, goldPrice, imageHost, fontManage, goldRate } = this.state
            store.set(storageName, {
              screen,
              goldPrice,
              imageHost,
              fontManage,
              goldRate,
              store_id: data.store_id,
              company_id: data.company_id
            })
            this.loading = false
            resolve(true)
          })
        } else {
          this.updateErrorMessages({
            action: 'add',
            error: 'REQUEST_ERROR',
            message: res?.data?.msg || 'REQUEST ERROR'
          })
          this.loading = false
          resolve(false)
        }
      } catch (error) {
        this.loading = false
        this.updateErrorMessages({
          action: 'add',
          error: 'REQUEST_ERROR',
          message: 'REQUEST ERROR'
        })
        resolve(false)
      }
    })
  }

  async loadFonts () {
    const { imageHost, fontManage, fontsLoaded } = this.state
    if (!fontManage) return
    const list = Object.values(fontManage)
    for (let index = 0; index < list.length; index++) {
      const item = list[index]
      if (!fontsLoaded.includes(item.id)) {
        const font = new FontFace(item.name, `url(${imageHost}${item.path})`)
        await font.load()
        document.fonts.add(font)
        this.setState({ fontsLoaded: [ ...fontsLoaded, item.id ] })
      }
    }
  }

  videoPlay () {
    const videos = document.querySelectorAll('video')
    for (let index = 0; index < videos.length; index++) {
      const video = videos[index]
      if (video.muted) video.play()
    }
  }

  getTime () {
    const arr = []
    const { widgetList, lang } = this.state.screen
    let _lang = lang || 'zh_CN'
    if (this.state.linkLang) _lang = this.state.linkLang
    dayjs.locale(LANGMMAP[_lang])
    for (let index = 0; index < widgetList.length; index++) {
      const element = widgetList[index]
      if (element.type === 'text' && element.model === '3') {
        arr.push({ uuid: element.uuid, format: element.time })
      }
    }
    let obj = {}
    arr.map(item => {
      obj[item.uuid] = dayjs().format(item.format)
      return item
    })
    this.setState({ timerManage: obj })
  }

  setTimer () {
    this.getTime()

    let count = 0
    this.timer = setInterval(async () => {
      this.getTime()

      count++
      const { refresh = 5 } = this.state.screen
      if (count === 60 * refresh) {
        count = 0
        if (!this.loading) {
          await this.getScreenInfo(true)
          if (this.shouldRefresh) {
            this.shouldRefresh = false
            window.location.reload()
          }
        }
      }
    }, 1000)
  }

  clearStorage () {
    store.remove(this.state.storageName)
  }

  refreshPage () {
    window.location.reload()
  }

  controlVideoPlay () {
    const videos = document.querySelectorAll('video')
    for (let index = 0; index < videos.length; index++) {
      const video = videos[index]
      if (this.state.play) {
        video.pause()
        this.setState({ play: false })
      } else {
        video.play()
        this.setState({ play: true })
      }
    }
  }

  controlVideoMuted () {
    const videos = document.querySelectorAll('video')
    for (let index = 0; index < videos.length; index++) {
      const video = videos[index]
      if (this.state.muted) {
        video.muted = false
        this.setState({ muted: false })
      } else {
        video.muted = true
        this.setState({ muted: true })
      }
    }
  }

  render () {
    const { windowWidth, windowHeight, screen, goldPrice, goldRate, imageHost, timerManage, errorMessages, fontManage, muted, play, linkRotate, linkLang } = this.state
    const { rotate, page, widgetList, lang } = screen
    let scale = 1, _rotate = rotate, _lang = lang || 'zh_CN'
    if (linkRotate) _rotate = linkRotate
    if (linkLang) _lang = linkLang
    const isHorizontal = [90, 270].includes(Math.abs(_rotate))
    if (isHorizontal) {
      //横向
      if (page.height / page.width > windowWidth / windowHeight) {
        scale = windowHeight / page.width
      } else {
        scale = windowWidth / page.height
      }
    } else {
      //竖向
      if (page.width / page.height > windowWidth / windowHeight) {
        scale = windowHeight / page.height
      } else {
        scale = windowWidth / page.width
      }
    }
    const showError = Object.keys(errorMessages).length > 0
    return (
      <Fragment>
        <div
          className='screen-page'
          style={{
            width: `${page.width}px`,
            height: `${page.height}px`,
            background: page.model === '2' && page.backgroundImage ? `${page.backgroundColor} url(${imageHost}${page.backgroundImage})` : page.backgroundColor,
            transform: `translate(-50%, -50%) rotate(${_rotate}deg) scale(${scale})`
          }}
        >
          {
            widgetList.map((widget, index) => {
              return (
                <Fragment key={widget.uuid}>
                  {widget.type === 'image' && <div
                    id={`widget-${widget.uuid}`}
                    className='widget-image widget'
                    style={{
                      left: `${widget.left - page.left}px`,
                      top: `${widget.top - page.top}px`,
                      width: `${widget.width}px`,
                      height: `${widget.height}px`,
                      opacity: widget.opacity,
                      transform: `translate(${widget.translateX}px, ${widget.translateY}px) rotate(${widget.rotate}deg)`
                    }}
                  >
                    <div
                      className='image-box'
                      style={{
                        borderRadius: `${widget.radius}%`,
                        transform: widget.flip ? `rotate${widget.flip}(180deg)` : 'none'
                      }}
                    >
                      <Swiper
                        id={`swiper-${widget.uuid}`}
                        className={`swiper-${widget.uuid}`}
                        style={{ height: '100%' }}
                        loop={true}
                        allowTouchMove={false}
                        autoplay={widget.urls.length > 1 ? { delay: widget.duration * 1000 } : false}
                        effect={widget.effect}
                        speed={widget.speed * 100}
                        observer
                        observeParents
                        observeSlideChildren
                      >
                        {
                          widget.urls.map((image, row) => {
                            return (
                              <SwiperSlide
                                className='image-slide'
                                key={row}
                                style={{ backgroundImage: `url(${imageHost}${image.link})` }}
                              ></SwiperSlide>
                            )
                          })
                        }
                      </Swiper>
                    </div>
                  </div>}
                  {widget.type === 'text' && <div
                    id={`widget-${widget.uuid}`}
                    className='widget-text widget'
                    style={{
                      left: `${widget.left - page.left}px`,
                      top: `${widget.top - page.top}px`,
                      width: `${widget.width}px`,
                      opacity: widget.opacity,
                      transform: `translate(${widget.translateX}px, ${widget.translateY}px) rotate(${widget.rotate}deg)`,
                      color: widget.color,
                      backgroundColor: widget.backgroundColor,
                      fontSize: `${widget.fontSize}px`,
                      letterSpacing: `${widget.letterSpacing}px`,
                      lineHeight: widget.lineHeight,
                      textAlign: widget.textAlign,
                      fontWeight: widget.fontWeight,
                      fontStyle: widget.fontStyle,
                      textDecoration: widget.textDecoration,
                      writingMode: widget.writingMode,
                      padding: `${widget.paddingTop}px ${widget.paddingRight}px ${widget.paddingBottom}px ${widget.paddingLeft}px`,
                      border: `${widget.borderWidth}px ${widget.borderStyle} ${widget.borderColor}`
                    }}    
                  >
                    <div
                      className='text-content'
                      style={{
                        transform: widget.flip ? `rotate${widget.flip}(180deg)` : 'none',
                        fontFamily: fontManage[widget.fontFamily]?.name || 'inherit'
                      }}>
                      {widget.model === '1' && <Fragment>{ widget.text }</Fragment>}
                      {widget.model === '2' && <Fragment>{ this.formatNumber(parseFloat(goldPrice[widget.relate]?.price || 0) * parseFloat(goldRate || 0), widget.fixed || 0) }</Fragment>}
                      {widget.model === '3' && <Fragment>{ timerManage[widget.uuid] }</Fragment>}
                    </div>
                  </div>}
                  {widget.type === 'video' && <div
                    id={`widget-${widget.uuid}`}
                    className='widget-video widget'
                    style={{
                      left: `${widget.left - page.left}px`,
                      top: `${widget.top - page.top}px`,
                      width: `${widget.width}px`,
                      height: `${widget.height}px`,
                      transform: `translate(${widget.translateX}px, ${widget.translateY}px) rotate(${widget.rotate}deg)`
                    }}
                  >
                    <video
                      src={`${imageHost}${widget.url.link}`}
                      autoPlay
                      loop
                      preload='metadata'
                      muted={widget.muted}
                    ></video>
                  </div>}
                  {widget.type === 'table' && <div
                    id={`widget-${widget.uuid}`}
                    className='widget-table widget'
                    style={{
                      left: `${widget.left - page.left}px`,
                      top: `${widget.top - page.top}px`,
                      width: `${widget.width}px`,
                      height: `${widget.height}px`,
                      opacity: widget.opacity,
                      transform: `translate(${widget.translateX}px, ${widget.translateY}px) rotate(${widget.rotate}deg)`,
                      fontSize: `${widget.fontSize}px`,
                      color: widget.color,
                      backgroundColor: widget.backgroundColor,
                      border: `${widget.borderWidth}px ${widget.borderStyle} ${widget.borderColor}`,
                    }}
                  >
                    {
                      widget.table.map((tableRow, row) => {
                        return (
                          <div
                            className='table-row'
                            key={row}
                            style={{ borderTop: `${widget.borderWidth}px ${widget.borderStyle} ${widget.borderColor}` }}
                          >
                            {
                              tableRow.map((tableCol, col) => {
                                return (
                                  <div
                                    className='table-col'
                                    key={col}
                                    style={{
                                      fontSize: `${tableCol.fontSize}px`,
                                      color: tableCol.color,
                                      backgroundColor: tableCol.backgroundColor,
                                      borderLeft: `${widget.borderWidth}px ${widget.borderStyle} ${widget.borderColor}`,
                                      fontFamily: fontManage[tableCol.fontFamily]?.name || 'inherit'
                                    }}
                                  >
                                    {tableCol.type === '1' && <span>{ tableCol.text }</span>}
                                    {tableCol.type === '2' && <span>{ this.formatNumber(parseFloat(goldPrice[tableCol.relate]?.price || 0) * parseFloat(goldRate || 0), tableCol.fixed || 0) }</span>}
                                  </div>
                                )
                              })
                            }
                          </div>
                        )
                      })
                    }  
                  </div>}
                </Fragment>
              )
            })
          }
        </div>
        {!showError && <div className='loading' style={{ backgroundImage: `url(${ImageLoading})` }}></div>}
        {showError && <div
          className='error-message'
          style={{
            width: `${isHorizontal ? windowHeight : windowWidth}px`,
            height: `${isHorizontal ? windowWidth : windowHeight}px`,
            transform: `translate(-50%, -50%) rotate(${_rotate}deg)`
          }}
        >
          {errorMessages['NETWORK_ERROR'] && <div className='error-text fade'>{ errorMessages['NETWORK_ERROR'].message }</div>}
          {!errorMessages['NETWORK_ERROR'] && errorMessages['REQUEST_ERROR'] && <div className='error-text fade'>{ errorMessages['REQUEST_ERROR'].message }</div>}
          {!errorMessages['NETWORK_ERROR'] && !errorMessages['REQUEST_ERROR'] && errorMessages['LINK_ERROR'] && <div className='error-text fade'>{ errorMessages['LINK_ERROR'].message }</div>}
        </div>}
        <div
          className='options-mask'
          style={{
            width: `${isHorizontal ? windowHeight : windowWidth}px`,
            height: `${isHorizontal ? windowWidth : windowHeight}px`,
            transform: `translate(-50%, -50%) rotate(${_rotate}deg)`
          }}
        >
          <div className='options-button first' onClick={this.refreshPage.bind(this)}>{ I18NMAP[_lang]?.refresh || '刷新' }</div>
          <div className='options-button second' onClick={this.clearStorage.bind(this)}>{ I18NMAP[_lang]?.clear || '清缓存' }</div>
          <div className='options-button third' onClick={this.controlVideoPlay.bind(this)}>{ play ? (I18NMAP[_lang]?.pause || '暂停') : (I18NMAP[_lang]?.play || '播放') }</div>
          <div className='options-button four' onClick={this.controlVideoMuted.bind(this)}>{ muted ? (I18NMAP[_lang]?.unmute || '声音') : (I18NMAP[_lang]?.mute || '静音') }</div>
        </div>
      </Fragment>
    )
  }
}

export default Screen
